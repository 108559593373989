import yup from "@/yup";

export function useUpdateAgentPosSettingsSchema() {
  return yup.object({
    posSettings: yup.object({
      shopBalance: yup.boolean().optional(),
      deposit: yup.boolean().optional(),
      voucherInfo: yup.boolean().optional(),
      memberCard: yup.boolean().optional(),
      kiosks: yup.boolean().optional(),
    }),
  });
}

export type PosSettingsSchema = yup.InferType<
  ReturnType<typeof useUpdateAgentPosSettingsSchema>
>;

import yup from "@/yup";

export function usePlayerUpdateFormSchema() {
  return yup.object({
    password: yup
      .string()
      .min(6)
      .transform((value) => (!value ? undefined : value)),
    name: yup.lazy((value) => {
      if (value === "") {
        return yup.string().defined();
      }
      return yup.string().min(3).max(255).defined();
    }),
    email: yup.lazy((value) => {
      if (value === "") {
        return yup.string().defined();
      }
      return yup.string().email().min(6).max(255).defined();
    }),
    phone: yup.lazy((value) => {
      if (value === "") {
        return yup.string().defined();
      }
      return yup.string().min(6).max(255).defined();
    }),
    isBlocked: yup.boolean(),
    kycApproved: yup
      .boolean()
      .nullable()
      .transform((value) => (value === null ? undefined : value)),
  });
}
export type PlayerUpdateFormSchema = yup.InferType<
  ReturnType<typeof usePlayerUpdateFormSchema>
>;

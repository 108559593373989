import yup from "@/yup";

export function useAddKioskFormSchema() {
  return yup.object({
    name: yup.string().min(3).required(),
    pairingCode: yup.string().pairingCode().required(),
    type: yup
      .mixed<"kiosk" | "exchanger" | "internetcafe">()
      .oneOf(["kiosk", "exchanger", "internetcafe"])
      .required(),
    isAlwaysAuthorized: yup
      .mixed<"true" | "false" | "default">()
      .oneOf(["true", "false", "default"])
      .required(),
    isPayoutAllowed: yup.boolean().required(),
    note: yup.string().optional(),
  });
}

export type AddKioskFormSchema = yup.InferType<
  ReturnType<typeof useAddKioskFormSchema>
>;

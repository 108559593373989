import { observer } from "mobx-react-lite";

import { usePosSettingsState } from "./pos-settings.state";
import { PosSettingsView } from "./pos-settings.view";

export interface PosSettingsTemplateProps {
  entity: { agentId: number };
}

export const PosSettings = observer<PosSettingsTemplateProps>((props) => {
  const state = usePosSettingsState(props);
  return <PosSettingsView state={state} />;
});

import { Space, Form, Button, Typography, Row, Col } from "antd";
import { reaction } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import {
  FormInput,
  FormItem,
  FormPassword,
  FormSelect,
  FormSwitch,
} from "@/ui/_common_/form-helpers";
import { FormSpinner } from "@/ui/_common_/form-spinner";

import { HallSettingsFormState } from "./hall-settings-form.state";

interface Props {
  state: HallSettingsFormState;
}

export const HallSettingsFormView = observer(({ state }: Props) => {
  const { intl, form, hallQuery, parentAgentQuery, submitQuery, userStore } =
    state;

  useEffect(() => {
    reaction(
      () => submitQuery.isFulfilled,
      () => form.resetField("password"),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (hallQuery.isIdle || hallQuery.isPending || parentAgentQuery.isPending) {
    return <FormSpinner />;
  }

  if (hallQuery.isRejected || parentAgentQuery.isRejected) {
    return <ErrorsFormatter queries={[hallQuery, parentAgentQuery]} />;
  }

  const hall = hallQuery.data;
  const parentAgent = parentAgentQuery.data;

  return (
    <>
      <ErrorsFormatter queries={[submitQuery]} isSticky={true} />
      <Space direction="vertical">
        <Form
          labelCol={{ span: 10 }}
          labelAlign="left"
          layout="horizontal"
          onFinish={form.handleSubmit(submitQuery.submit)}
        >
          <Form.Item
            label={intl.formatMessage({ defaultMessage: "Parent agent" })}
          >
            {parentAgent?.name ? (
              <Typography.Text copyable>{parentAgent.name}</Typography.Text>
            ) : (
              "—"
            )}
          </Form.Item>
          <Form.Item label={intl.formatMessage({ defaultMessage: "Name" })}>
            <Typography.Text copyable>{hall.name}</Typography.Text>
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ defaultMessage: "Admin username" })}
          >
            <Typography.Text copyable>{hall.login}</Typography.Text>
          </Form.Item>
          <FormItem
            form={form}
            path="password"
            label={intl.formatMessage({ defaultMessage: "Admin password" })}
          >
            <FormPassword
              form={form}
              path="password"
              placeholder={intl.formatMessage({
                defaultMessage: "Enter password",
              })}
            />
          </FormItem>
          <FormItem
            form={form}
            path="isBalanceUnlimited"
            label={intl.formatMessage({
              defaultMessage: "Unlimited balance",
            })}
          >
            <FormSwitch form={form} path="isBalanceUnlimited" />
          </FormItem>
          <FormItem
            form={form}
            path="isBlocked"
            label={intl.formatMessage({ defaultMessage: "Blocked" })}
          >
            <FormSwitch
              form={form}
              path="isBlocked"
              isConfirmationNeeded={true}
            />
          </FormItem>
          <FormItem
            form={form}
            path="lobbySettings.isPanicEnabled"
            label={intl.formatMessage({ defaultMessage: "Panic" })}
          >
            <FormSwitch
              form={form}
              path="lobbySettings.isPanicEnabled"
              isConfirmationNeeded={true}
            />
          </FormItem>
          <FormItem
            form={form}
            path="lobbySettings.panicUrl"
            label={intl.formatMessage({ defaultMessage: "Panic URL" })}
          >
            <FormInput
              form={form}
              path="lobbySettings.panicUrl"
              placeholder={intl.formatMessage({
                defaultMessage: "Enter URL",
              })}
            />
          </FormItem>
          <FormItem
            form={form}
            path="isPanicBlock"
            label={intl.formatMessage({
              defaultMessage: "Block cashier on Panic",
            })}
          >
            <FormSwitch
              form={form}
              path="isPanicBlock"
              isConfirmationNeeded={true}
            />
          </FormItem>
          <Form.Item label={intl.formatMessage({ defaultMessage: "Currency" })}>
            {hall.currency ?? "—"}
          </Form.Item>
          <FormItem
            form={form}
            path="autoPrintReceipts"
            label={intl.formatMessage({
              defaultMessage: "Auto print receipts",
            })}
          >
            <FormSwitch form={form} path="autoPrintReceipts" />
          </FormItem>

          <FormItem
            form={form}
            path="configuredByParent"
            label={intl.formatMessage({
              defaultMessage: "Configured by parent",
            })}
          >
            <FormSwitch form={form} path="configuredByParent" />
          </FormItem>
          <FormItem
            form={form}
            path="kycType"
            label={intl.formatMessage({
              defaultMessage: "KYC Type",
            })}
          >
            <FormSelect
              form={form}
              path="kycType"
              options={[
                {
                  label: intl.formatMessage({ defaultMessage: "No need" }),
                  value: null,
                },
                {
                  label: intl.formatMessage({
                    defaultMessage: "Manual",
                  }),
                  value: "manual",
                },
                {
                  label: intl.formatMessage({
                    defaultMessage: "Provider",
                  }),
                  value: "provider",
                },
              ]}
              placeholder={intl.formatMessage({
                defaultMessage: "Select KYC Type",
              })}
            />
          </FormItem>
          <FormItem
            form={form}
            path="gameDisplayType"
            label={intl.formatMessage({
              defaultMessage: "Shop games for player",
            })}
          >
            <FormSelect
              form={form}
              path="gameDisplayType"
              options={[
                {
                  label: intl.formatMessage({ defaultMessage: "Always" }),
                  value: "always",
                },
                {
                  label: intl.formatMessage({
                    defaultMessage: "KYC confirmed",
                  }),
                  value: "kyc_confirmed",
                },
                {
                  label: intl.formatMessage({
                    defaultMessage: "KYC confirmed or Deposit",
                  }),
                  value: "kyc_confirmed_or_deposit",
                },
              ]}
              placeholder={intl.formatMessage({
                defaultMessage: "Shop games for player",
              })}
            />
          </FormItem>
          {["ClientAdmin", "AgentAdmin", "Manager", "Admin"].includes(
            userStore.user.role,
          ) && (
            <FormItem
              form={form}
              path="isConvActive"
              label={intl.formatMessage({ defaultMessage: "Instant messages" })}
            >
              <FormSwitch form={form} path="isConvActive" />
            </FormItem>
          )}
          <Row justify="end" gutter={12}>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                loading={submitQuery.isPending}
              >
                {intl.formatMessage({ defaultMessage: "Save" })}
              </Button>
            </Col>
          </Row>
        </Form>
      </Space>
    </>
  );
});

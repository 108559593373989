import { Tabs } from "antd";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import { AgentAPI, PermissionsAPI } from "@/api";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";
import { ConfiguredByParentProvider } from "@/ui/_common_/configured-by-parent-alert";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormSpinner } from "@/ui/_common_/form-spinner";
import { Bonuses } from "@/ui/bonuses";
import { Games } from "@/ui/games";
import { JackpotSettingsForm } from "@/ui/jackpots";
import { AgentLobbySettings } from "@/ui/lobby";
import { RulesTabs } from "@/ui/rules";
import { SportSettings } from "@/ui/sport-settings/form";
import { Sweepstakes } from "@/ui/sweepstakes";
import { Websites } from "@/ui/websites";
import { isTruthy } from "@/utilities";

import { AgentOtherSettings } from "../agent-other-settings";
import { GeneralForm } from "../general-form";
import { PosSettings, PosTemplate } from "../pos";

interface Props {
  agentId: number;
  setAgentName(name: string): void;
}

export const EditAgentTabs = observer<Props>(({ agentId, setAgentName }) => {
  const intl = useIntl();

  const { permissionsStore } = useGlobalStore();

  const agentQuery = useMemo(() => {
    const query = new Query(async () => {
      return await AgentAPI.getById({ id: agentId });
    });
    query.submit({});
    return query;
  }, [agentId]);

  const permissionsQuery = useMemo(() => {
    const query = new Query(PermissionsAPI.get);
    query.submit({ agentId });
    return query;
  }, [agentId]);

  if (permissionsQuery.isIdle || permissionsQuery.isPending) {
    return <FormSpinner />;
  }

  if (permissionsQuery.isRejected) {
    return <ErrorsFormatter queries={[permissionsQuery]} />;
  }

  return (
    <Tabs
      destroyInactiveTabPane={true}
      onChange={() => agentQuery.submit({})}
      items={[
        {
          key: "general",
          label: intl.formatMessage({ defaultMessage: "General" }),
          children: (
            <GeneralForm agentId={agentId} setAgentName={setAgentName} />
          ),
        },
        {
          key: "lobby",
          label: intl.formatMessage({ defaultMessage: "Lobby" }),
          children: <AgentLobbySettings agentId={agentId} />,
        },
        {
          key: "websites",
          label: intl.formatMessage({ defaultMessage: "Websites" }),
          children: <Websites.EditForm entity={{ agentId }} />,
        },
        {
          key: "games",
          label: intl.formatMessage({ defaultMessage: "Games" }),
          children: <Games.EditForm entity={{ agentId }} />,
        },
        {
          key: "bonuses",
          label: intl.formatMessage({ defaultMessage: "Bonuses" }),
          className: "subtabs",
          children: (
            <ConfiguredByParentProvider
              value={agentQuery.data?.configuredByParent ?? false}
            >
              <Bonuses.Tabs entity={{ agentId }} />
            </ConfiguredByParentProvider>
          ),
        },
        permissionsQuery.data.jackpots && {
          key: "jackpots",
          label: intl.formatMessage({ defaultMessage: "Jackpots" }),
          children: (
            <ConfiguredByParentProvider
              value={agentQuery.data?.configuredByParent ?? false}
            >
              <JackpotSettingsForm entity={{ agentId }} />
            </ConfiguredByParentProvider>
          ),
        },
        permissionsQuery.data.sweepstakes && {
          key: "sweepstakes",
          label: intl.formatMessage({ defaultMessage: "Sweepstakes" }),
          children: <Sweepstakes.EditForm entity={{ agentId }} />,
        },
        {
          key: "other",
          label: intl.formatMessage({ defaultMessage: "Other" }),
          children: <AgentOtherSettings agentId={agentId} />,
        },
        {
          key: "rules-and-terms",
          className: "subtabs",
          label: intl.formatMessage({ defaultMessage: "Rules and Terms" }),
          children: <RulesTabs entity={{ agentId }} />,
        },
        {
          key: "pos-settings",
          label: intl.formatMessage({ defaultMessage: "POS Settings" }),
          children: <PosSettings entity={{ agentId }} />,
        },
        {
          key: "pos-template",
          label: intl.formatMessage({ defaultMessage: "POS Template" }),
          children: <PosTemplate entity={{ agentId }} />,
        },
        permissionsStore.has("ViewSportSettings") && {
          key: "special",
          label: intl.formatMessage({ defaultMessage: "Special" }),
          children: <SportSettings entity={{ agentId }} />,
        },
      ].filter(isTruthy)}
    />
  );
});

import yup from "@/yup";

export function useHallSettingsFormSchema() {
  return yup.object({
    password: yup
      .string()
      .min(6)
      .transform((value) => (!value ? undefined : value)),
    isBalanceUnlimited: yup.boolean(),
    isBlocked: yup.boolean(),
    isPanicBlock: yup.boolean(),
    autoPrintReceipts: yup.boolean(),
    lobbySettings: yup.object({
      isPanicEnabled: yup.boolean().required(),
      panicUrl: yup.string().url().required(),
    }),
    configuredByParent: yup.boolean(),
    isConvActive: yup.boolean(),
    gameDisplayType: yup
      .mixed()
      .oneOf(["always", "kyc_confirmed", "kyc_confirmed_or_deposit", null]),
    kycType: yup.mixed().oneOf(["manual", "provider", null]),
  });
}

export type HallSettingsFormSchema = yup.InferType<
  ReturnType<typeof useHallSettingsFormSchema>
>;

import { yupResolver } from "@hookform/resolvers/yup";
import { notification } from "antd";
import { pickBy } from "lodash-es";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { PlayerAPI } from "@/api";
import { PlayerUpdated } from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";

import { PlayerUpdateFormProps } from "./player-update-form";
import {
  PlayerUpdateFormSchema,
  usePlayerUpdateFormSchema,
} from "./player-update-form.schema";

export function usePlayerUpdateFormState({
  playerId,
  setPlayerName,
}: PlayerUpdateFormProps) {
  const intl = useIntl();

  const { eventBusService } = useGlobalStore();

  const schema = usePlayerUpdateFormSchema();
  const form = useForm<PlayerUpdateFormSchema>({
    resolver: yupResolver(schema),
  });

  const playerQuery = useMemo(() => {
    const query = new Query(PlayerAPI.getById);
    query.submit({ id: playerId });
    return query;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const player = useMemo(() => {
    return playerQuery.data ?? null;
  }, [playerQuery.data]);

  useEffect(() => {
    if (!player) {
      return;
    }

    setPlayerName(player.login);

    const values = schema.cast(player, { assert: false, stripUnknown: true });
    form.reset(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player]);

  const submitQuery = useMemo(() => {
    return new Query(async (values: PlayerUpdateFormSchema) => {
      const computedValues = pickBy(values, (v) => v !== undefined);
      await PlayerAPI.update({
        id: playerId,
        ...computedValues,
      });

      notification.success({
        message: "",
        description: intl.formatMessage({
          defaultMessage: "The player has been successfully updated.",
        }),
      });

      eventBusService.publish(new PlayerUpdated({}));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    intl,
    form,
    playerQuery,
    submitQuery,
  };
}

export type PlayerUpdateFormState = ReturnType<typeof usePlayerUpdateFormState>;

import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, Col, Form, Input, Row, Space } from "antd";
import { has } from "lodash-es";
import { observer } from "mobx-react-lite";
import { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { ConfiguredByParentAlert } from "@/ui/_common_/configured-by-parent-alert";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormItem, FormSwitch } from "@/ui/_common_/form-helpers";
import { FormSpinner } from "@/ui/_common_/form-spinner";
import { InfoIcon } from "@/ui/_common_/icons";
import { centsToDollars } from "@/utilities";

import { FormState } from "./FormState";
import { ISchema, useSchema } from "./schema";

type Props = {
  state: FormState;
};

export const FormView: FC<Props> = observer(({ state }) => {
  const intl = useIntl();
  const schema = useSchema();
  const resolver = yupResolver(schema);

  const form = useForm<ISchema>({
    resolver,
    defaultValues: {
      isAutoLowBonusFinish: false,
    },
  });
  const { errors } = form.formState;

  useEffect(() => {
    const values = schema.cast(state.query?.data?.bonusSettings, {
      stripUnknown: true,
    });

    form.reset(centsToDollars(values, ["minWithdraw"]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.query?.data]);

  if (!state.query || state?.isIdleOrPending) {
    return <FormSpinner />;
  }

  const wager = form.watch("bonusWager");

  return (
    <Space direction="vertical">
      <ConfiguredByParentAlert />
      <ErrorsFormatter queries={[state.query]} />
      <Form
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={form.handleSubmit(state.handleSubmit)}
      >
        <FormItem
          form={form}
          path="isAutoLowBonusFinish"
          label={intl.formatMessage({
            defaultMessage: "Auto-completion of bonus wager for low balance",
          })}
        >
          <FormSwitch
            form={form}
            path="isAutoLowBonusFinish"
            disabled={state.readOnly}
          />
        </FormItem>
        <Form.Item
          label={intl.formatMessage({ defaultMessage: "Min. withdraw" })}
          validateStatus={has(errors, "minWithdraw") ? "error" : ""}
          help={errors.minWithdraw?.message}
        >
          <Controller
            control={form.control}
            name="minWithdraw"
            defaultValue={state?.query?.data?.bonusSettings.minWithdraw}
            render={({ field }) => (
              <Input
                value={field.value}
                onChange={(event) => {
                  const value = event.target.value;
                  if (!value.length || /^\d+$/.test(value)) {
                    field.onChange(value);
                  }
                }}
                placeholder={intl.formatMessage({
                  defaultMessage: "Enter min. withdraw",
                })}
                autoFocus
                disabled={state.readOnly}
              />
            )}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ defaultMessage: "Wager" })}
          validateStatus={has(errors, "bonusWager") ? "error" : ""}
          help={errors.bonusWager?.message}
        >
          <Space direction="vertical">
            <Controller
              control={form.control}
              name="bonusWager"
              defaultValue={state?.query?.data?.bonusSettings.bonusWager}
              render={({ field }) => (
                <Input
                  value={field.value}
                  onChange={(event) => {
                    const value = event.target.value;
                    if (!value.length || /^\d+$/.test(value)) {
                      field.onChange(value);
                    }
                  }}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Enter wager",
                  })}
                  autoFocus
                  disabled={state.readOnly}
                />
              )}
            />
            {Number(wager) === 1 && (
              <Alert
                type="warning"
                showIcon
                icon={<InfoIcon style={{ width: 20 }} />}
                message={intl.formatMessage(
                  {
                    defaultMessage: `Attention! Wager = 1. Financial risks are possible.{br}
                    Set wager value to 1 only if you are completely sure of what you are doing.{br}
                    Recommended value: 2 or more.`,
                  },
                  { br: <br /> },
                )}
              />
            )}
          </Space>
        </Form.Item>
        {!state.readOnly && (
          <Row justify="end" gutter={12}>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                loading={state?.updateQuery?.isPending}
              >
                {intl.formatMessage({ defaultMessage: "Save" })}
              </Button>
            </Col>
          </Row>
        )}
      </Form>
    </Space>
  );
});

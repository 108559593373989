import { Space, Form, Row, Button, Col } from "antd";
import { observer } from "mobx-react-lite";

import ErrorsFormatter from "../../_common_/errors-formatter";
import { FormItem, FormSwitch } from "../../_common_/form-helpers";
import { FormSpinner } from "../../_common_/form-spinner";

import { HallOtherSettingsState } from "./hall-other-settings.state";

interface Props {
  state: HallOtherSettingsState;
}

export const HallOtherSettingsView = observer(({ state }: Props) => {
  const { intl, form, hallQuery, submitQuery } = state;

  if (hallQuery.isIdle || hallQuery.isPending) {
    return <FormSpinner />;
  }

  if (hallQuery.isRejected) {
    return <ErrorsFormatter queries={[hallQuery]} />;
  }

  return (
    <Space direction="vertical">
      <ErrorsFormatter queries={[submitQuery]} />
      <Form
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={form.handleSubmit(submitQuery.submit)}
      >
        <FormItem
          isColonShown={false}
          form={form}
          path="cashierSettings.isShortPlayerCreation"
          label={intl.formatMessage({
            defaultMessage: "Player creation: short form",
          })}
        >
          <FormSwitch
            form={form}
            path="cashierSettings.isShortPlayerCreation"
          />
        </FormItem>
        <Row justify="end" gutter={12}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={submitQuery.isPending}
            >
              {intl.formatMessage({ defaultMessage: "Save" })}
            </Button>
          </Col>
        </Row>
      </Form>
    </Space>
  );
});

import { Button, Card, Col, Row, Space, Spin, Table } from "antd";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import { TPaymentReportItem } from "@/api";
import { useViewModel } from "@/hooks/use-view-model";
import { useGlobalStore } from "@/stores";
import DateRangePicker from "@/ui/_common_/date-range-picker";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import MoneyFormatter from "@/ui/_common_/money-formatter";
import Page from "@/ui/_common_/page";
import Pagination from "@/ui/_common_/pagination";
import Selector from "@/ui/_common_/selector";

import { MethodSelect } from "./MethodSelect";
import { PaymentProviderSelect } from "./PaymentProviderSelect";
import { PaymentsReportState } from "./PaymentsReportState";
import { StatusSelect } from "./StatusSelect";
import { TypeSelect } from "./TypeSelect";
import { VerificationSelect } from "./VerificationSelect";

function Payments() {
  const globalStore = useGlobalStore();
  const state = useViewModel(() => new PaymentsReportState(globalStore), {});
  const intl = useIntl();
  const { permissionsStore } = globalStore;
  return (
    <Page title={intl.formatMessage({ defaultMessage: "Payments report" })}>
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          {permissionsStore.has("SelectClient") && (
            <Col xs={24} md={8} lg={6} xl={4}>
              <Selector
                store={state.clientSelectorStore}
                placeholder={intl.formatMessage({
                  defaultMessage: "Select client",
                })}
              />
            </Col>
          )}
          {!!state.clientSelectorStore.selectedId && (
            <>
              {permissionsStore.has("SelectAgent") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.agentSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select an agent",
                    })}
                  />
                </Col>
              )}
              {permissionsStore.has("SelectSubagent") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.subagentSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select subagent",
                    })}
                  />
                </Col>
              )}
              <Col xs={24} md={8} lg={6} xl={4}>
                <Selector
                  store={state.playerSelectorStore}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Select player",
                  })}
                />
              </Col>
            </>
          )}
        </Row>
      </Card>
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          <Col xs={24} md={8} lg={6} xl={4}>
            <TypeSelect value={state.type} onChange={state.setType} />
          </Col>
          <Col xs={24} md={8} lg={6} xl={4}>
            <PaymentProviderSelect
              value={state.paymentProvider}
              onChange={state.setPaymentProvider}
            />
          </Col>
          <Col xs={24} md={8} lg={6} xl={4}>
            <MethodSelect value={state.method} onChange={state.setMethod} />
          </Col>
          <Col xs={24} md={8} lg={6} xl={4}>
            <StatusSelect value={state.status} onChange={state.setStatus} />
          </Col>
          <Col xs={24} md={8} lg={6} xl={4}>
            <VerificationSelect
              value={state.verificationStatus}
              onChange={state.setVerificationStatus}
            />
          </Col>
          <Col>
            <DateRangePicker store={state} />
          </Col>
          <Col xs={12} md={6} lg={6} xl={3} style={{ marginLeft: "auto" }}>
            <Button
              style={{ width: "100%" }}
              type="primary"
              disabled={!state.clientSelectorStore.selectedId}
              onClick={state.apply}
            >
              {intl.formatMessage({ defaultMessage: "Apply" })}
            </Button>
          </Col>
        </Row>
      </Card>
      <Card size="small">
        <Spin spinning={state.filterQuery.isPending}>
          <Space direction="vertical">
            <ErrorsFormatter queries={[state.filterQuery]} />
            <Table
              dataSource={state.filterQuery.items}
              showHeader={!!state.filterQuery.items.length}
              rowKey="id"
              size="small"
              bordered
              loading={state.filterQuery.isPending}
              pagination={false}
            >
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "ID" })}
                dataIndex="id"
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Date" })}
                dataIndex="createdAt"
                render={(_, item: TPaymentReportItem) =>
                  new Date(item.createdAt).toLocaleString()
                }
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Client" })}
                dataIndex="clientName"
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Agent" })}
                dataIndex="agentName"
                render={(agentName) => agentName ?? "—"}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Shop" })}
                dataIndex="hallName"
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Player" })}
                dataIndex="playerName"
              />
              <Table.Column
                align="right"
                title={intl.formatMessage({ defaultMessage: "Amount" })}
                dataIndex="amount"
                render={(_, item: TPaymentReportItem) => (
                  <MoneyFormatter cents={item.amount} />
                )}
              />
              <Table.Column
                title={intl.formatMessage({
                  defaultMessage: "Currency",
                })}
                dataIndex="currency"
                align="right"
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Type" })}
                dataIndex="type"
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Provider" })}
                dataIndex="providerStringId"
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Method" })}
                dataIndex="methodType"
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Status" })}
                dataIndex="status"
              />
              <Table.Column
                title={intl.formatMessage({
                  defaultMessage: "Review Status",
                })}
                dataIndex="verificationStatus"
                render={(status) => status || "—"}
              />
            </Table>
            <Pagination
              query={state.filterQuery}
              onChange={() => {
                state.filter({ preservePageNumber: true });
              }}
            />
          </Space>
        </Spin>
      </Card>
    </Page>
  );
}

export default observer(Payments);

import { yupResolver } from "@hookform/resolvers/yup";
import { notification } from "antd";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { AgentAPI } from "@/api";
import Query from "@/models/query";

import { PosSettingsTemplateProps } from "./pos-settings";
import {
  PosSettingsSchema,
  useUpdateAgentPosSettingsSchema,
} from "./pos-settings.schema";

export function usePosSettingsState(props: PosSettingsTemplateProps) {
  const intl = useIntl();

  const schema = useUpdateAgentPosSettingsSchema();
  const form = useForm<PosSettingsSchema>({
    resolver: yupResolver(schema),
  });

  const formValues = form.watch();

  const posSettingsQuery = useMemo(() => {
    const query = new Query(async (_: void) => {
      return await AgentAPI.getPosSettings({ id: props.entity.agentId });
    });

    query.submit().then(() => {
      const posSettings = posSettingsQuery.data!;
      const values = schema.cast({ posSettings }, { stripUnknown: true });
      form.reset(values);
    });

    return query;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitQuery = useMemo(() => {
    return new Query(async (updateParams: PosSettingsSchema) => {
      await AgentAPI.updatePosSettings({
        id: props.entity.agentId,
        ...updateParams.posSettings,
      });

      notification.success({
        message: "",
        description: intl.formatMessage({
          defaultMessage: "POS settings have been successfully updated.",
        }),
      });
      await posSettingsQuery.submit();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    intl,
    submitQuery,
    settingsQuery: posSettingsQuery,
    formValues,
    form,
  };
}

export type PosSettingsState = ReturnType<typeof usePosSettingsState>;

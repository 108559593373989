import { notification } from "antd";
import { makeAutoObservable } from "mobx";

import { AgentAPI, HallAPI } from "@/api";
import { ViewModel } from "@/hooks/use-view-model";
import Query from "@/models/query";
import { IntlService } from "@/services";
import { PermissionsStore } from "@/stores";
import { Entity } from "@/types";
import { dollarsToCents } from "@/utilities";

import { Props } from "./Form";
import { ISchema } from "./schema";

export class FormState implements ViewModel<Props> {
  constructor(
    private intlService: IntlService,
    public permissionsStore: PermissionsStore,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private _entity?: Entity;
  private _entityId?: number;
  public _query?: Query;
  public _updateQuery?: Query;

  get entity() {
    return this._entity!;
  }

  private _readOnly = false;

  get readOnly() {
    return this._readOnly;
  }

  onViewMount = async (props: Props) => {
    this._entity = props.entity;
    this._readOnly = !!props.readOnly;

    if ("hallId" in props.entity) {
      await Promise.allSettled([
        this.hallQuery.submit({ id: props.entity.hallId }),
      ]);

      this.resolveQueryType(
        this.hallQuery,
        this.updateHallQuery,
        props.entity.hallId,
      );
    }

    if ("agentId" in props.entity) {
      await Promise.allSettled([
        this.agentQuery.submit({ id: props.entity.agentId }),
      ]);

      this.resolveQueryType(
        this.agentQuery,
        this.updateAgentQuery,
        props.entity.agentId,
      );
    }
  };

  private resolveQueryType = (
    entityQuery: Query,
    entityUpdateQuery: Query,
    entityId: number,
  ) => {
    this._query = entityQuery;
    this._updateQuery = entityUpdateQuery;
    this._entityId = entityId;
  };

  onViewUpdate = async (props: Props) => {
    this._entity = props.entity;
  };

  onViewUnmount = () => {
    this._entity = undefined;
  };

  hallQuery = new Query(HallAPI.getById);
  agentQuery = new Query(AgentAPI.getById);

  updateHallQuery = new Query(HallAPI.update);
  updateAgentQuery = new Query(AgentAPI.update);

  get query() {
    return this._query!;
  }

  get updateQuery() {
    return this._updateQuery!;
  }

  get isIdleOrPending() {
    return this.query?.isIdle || this.query?.isPending;
  }

  public handleSubmit = async (values: ISchema) => {
    const bonusSettings = dollarsToCents(values, ["minWithdraw"]);

    await this.updateQuery.submit({
      id: this._entityId,
      bonusSettings,
    });

    if (!this.updateQuery.isFulfilled) {
      return;
    }

    notification.success({
      message: "",
      description: this.intlService.intl.formatMessage({
        defaultMessage:
          "The common bonus settings have been successfully updated.",
      }),
    });
  };
}

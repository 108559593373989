import { yupResolver } from "@hookform/resolvers/yup";
import { notification } from "antd";
import { pick } from "lodash-es";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { AgentAPI, HallAPI } from "@/api";
import { HallUpdated } from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";

import { HallSettingsFormProps } from "./hall-settings-form";
import {
  HallSettingsFormSchema,
  useHallSettingsFormSchema,
} from "./hall-settings-form.schema";

export function useHallSettingsFormState({
  hallId,
  setHallName,
}: HallSettingsFormProps) {
  const intl = useIntl();

  const { userStore } = useGlobalStore();

  const schema = useHallSettingsFormSchema();
  const form = useForm<HallSettingsFormSchema>({
    resolver: yupResolver(schema),
  });

  const { hallQuery, parentAgentQuery } = useMemo(() => {
    const hallQuery = new Query(HallAPI.getById);
    const parentAgentQuery = new Query(AgentAPI.getById);
    hallQuery.submit({ id: hallId }).then(() => {
      const hall = hallQuery.data!;
      setHallName(hall.name);
      form.reset(pick(hall, Object.keys(schema.fields)));
      if (hall.agentId) {
        parentAgentQuery.submit({ id: hall.agentId });
      }
    });
    return { hallQuery, parentAgentQuery };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { eventBusService } = useGlobalStore();

  const submitQuery = useMemo(() => {
    return new Query(async (values: HallSettingsFormSchema) => {
      await HallAPI.update({ id: hallId, ...values });

      notification.success({
        message: "",
        description: intl.formatMessage({
          defaultMessage: "The shop has been successfully updated.",
        }),
      });

      eventBusService.publish(new HallUpdated({ hallId }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { intl, form, hallQuery, parentAgentQuery, submitQuery, userStore };
}

export type HallSettingsFormState = ReturnType<typeof useHallSettingsFormState>;

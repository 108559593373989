import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Form, Row, Space } from "antd";
import { Divider } from "antd/es";
import { observer } from "mobx-react-lite";
import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { ConfiguredByParentAlert } from "@/ui/_common_/configured-by-parent-alert";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormItem, FormSelect, FormSwitch } from "@/ui/_common_/form-helpers";
import { FormSpinner } from "@/ui/_common_/form-spinner";

import { FormState } from "./FormState";
import { IAutoIssuesSchema, useSchema } from "./schema";

type Props = {
  state: FormState;
};

export const FormView: FC<Props> = observer(({ state }) => {
  const intl = useIntl();
  const schema = useSchema();
  const resolver = yupResolver(schema);

  const form = useForm<IAutoIssuesSchema>({
    resolver,
    defaultValues: {
      autoIssueBonusStrategy: null,
    },
  });

  useEffect(() => {
    const values = schema.cast(state.query?.data?.bonusSettings, {
      stripUnknown: true,
    });

    form.reset(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.query?.data]);

  if (!state.query || state?.isIdleOrPending) {
    return <FormSpinner />;
  }

  const strategy = form.watch("autoIssueBonusStrategy");
  const strategyPsp = form.watch("autoIssueBonusPspStrategy");

  return (
    <Space direction="vertical">
      <ConfiguredByParentAlert />
      <ErrorsFormatter queries={[state.query]} />
      <Form
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={form.handleSubmit(state.handleSubmit)}
      >
        <FormItem
          form={form}
          path="autoIssueBonusKioskDeposit"
          label={intl.formatMessage({
            defaultMessage: "Auto issue bonus at kiosks",
          })}
        >
          <FormSwitch
            form={form}
            path="autoIssueBonusKioskDeposit"
            disabled={state.readOnly}
          />
        </FormItem>
        <FormItem
          form={form}
          path="autoIssueBonusStrategy"
          label={intl.formatMessage({
            defaultMessage: "Auto issue bonus strategy",
          })}
        >
          <FormSelect
            form={form}
            path="autoIssueBonusStrategy"
            options={[
              {
                label: intl.formatMessage({ defaultMessage: "None" }),
                value: null,
              },
              {
                label: intl.formatMessage({ defaultMessage: "Specific bonus" }),
                value: "chooseBonus",
              },
              {
                label: intl.formatMessage({
                  defaultMessage: "Deposit depending bonus",
                }),
                value: "autoHandleThreshold",
              },
            ]}
            placeholder={intl.formatMessage({
              defaultMessage: "Select strategy",
            })}
            disabled={state.readOnly}
          />
        </FormItem>
        {strategy === "chooseBonus" && (
          <>
            <FormItem
              form={form}
              path="chooseBonusId"
              label={intl.formatMessage({ defaultMessage: "Bonus" })}
            >
              <FormSelect
                form={form}
                path="chooseBonusId"
                options={state.bonusesOptions}
                placeholder={intl.formatMessage({
                  defaultMessage: "Select bonus",
                })}
                disabled={state.readOnly}
              />
            </FormItem>
          </>
        )}
        <Divider />
        <FormItem
          form={form}
          path="autoIssueBonusPspDeposit"
          label={intl.formatMessage({
            defaultMessage: "Auto issue bonus at PSP",
          })}
        >
          <FormSwitch
            form={form}
            path="autoIssueBonusPspDeposit"
            disabled={state.readOnly}
          />
        </FormItem>
        <FormItem
          form={form}
          path="autoIssueBonusPspStrategy"
          label={intl.formatMessage({
            defaultMessage: "Auto issue bonus PSP strategy",
          })}
        >
          <FormSelect
            form={form}
            path="autoIssueBonusPspStrategy"
            options={[
              {
                label: intl.formatMessage({ defaultMessage: "None" }),
                value: null,
              },
              {
                label: intl.formatMessage({ defaultMessage: "Specific bonus" }),
                value: "chooseBonus",
              },
              {
                label: intl.formatMessage({
                  defaultMessage: "Deposit depending bonus",
                }),
                value: "autoHandleThreshold",
              },
            ]}
            placeholder={intl.formatMessage({
              defaultMessage: "Select strategy",
            })}
            disabled={state.readOnly}
          />
        </FormItem>
        {strategyPsp === "chooseBonus" && (
          <FormItem
            form={form}
            path="chooseBonusPspId"
            label={intl.formatMessage({ defaultMessage: "PSP Specific Bonus" })}
          >
            <FormSelect
              form={form}
              path="chooseBonusPspId"
              options={state.bonusesOptions}
              placeholder={intl.formatMessage({
                defaultMessage: "Select bonus",
              })}
              disabled={state.readOnly}
            />
          </FormItem>
        )}
        {/* {strategy === "twoBonuses" && (
          <FormItem
            form={form}
            path="bonuses"
            label={intl.formatMessage({ defaultMessage: "Bonuses" })}
          >
            <Space direction="vertical">
              <FormSelect
                form={form}
                path="bonuses.0.bonusId"
                options={state.bonusesOptions}
                placeholder={intl.formatMessage({
                  defaultMessage: "Select bonus 1",
                })}
              />
              <FormSelect
                form={form}
                path="bonuses.1.bonusId"
                options={state.bonusesOptions}
                placeholder={intl.formatMessage({
                  defaultMessage: "Select bonus 2",
                })}
              />
            </Space>
          </FormItem>
        )} */}
        {!state.readOnly && (
          <Row justify="end" gutter={12}>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                loading={state?.updateQuery?.isPending}
              >
                {intl.formatMessage({ defaultMessage: "Save" })}
              </Button>
            </Col>
          </Row>
        )}
      </Form>
    </Space>
  );
});

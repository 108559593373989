import {
  Button,
  Card,
  Col,
  Divider,
  PageHeader,
  Row,
  Space,
  Typography,
} from "antd";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useParams, useNavigate } from "react-router-dom";

import { PlayerAPI } from "@/api";
import { CheckPlayerBonuses } from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";
import { AncestorsTreeButton } from "@/ui/_common_/ancestors-tree";
import AreYouSure from "@/ui/_common_/are-you-sure";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormSpinner } from "@/ui/_common_/form-spinner";
import MoneyFormatter from "@/ui/_common_/money-formatter";
import Page from "@/ui/_common_/page";
import CurrentBonus from "@/ui/players/withdraw/modal-bonuses";

export const PlayerDetails = observer(() => {
  const intl = useIntl();
  const { id } = useParams();
  const navigate = useNavigate();
  const { permissionsStore, eventBusService } = useGlobalStore();

  const query = useMemo(() => {
    const query = new Query(PlayerAPI.getInfo);
    query.submit({ id: Number(id) });
    return query;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cancelBonusQuery = useMemo(
    () =>
      new Query<void, void>(async () => {
        await PlayerAPI.cancelBonus({
          id: Number(id),
          type: "fullCancel",
        });
        const info = await PlayerAPI.getInfo({ id: Number(id) });
        query.resolve({ id: Number(id) }, info);
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const resetWagerQuery = useMemo(
    () =>
      new Query<void, void>(async () => {
        await PlayerAPI.cancelBonus({
          id: Number(id),
          type: "wagerReset",
        });
        const info = await PlayerAPI.getInfo({ id: Number(id) });
        query.resolve({ id: Number(id) }, info);
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (query.isIdle || query.isPending) {
    return (
      <Page
        title={intl.formatMessage({
          defaultMessage: "Player details",
        })}
      >
        <FormSpinner />
      </Page>
    );
  }

  if (query.isRejected) {
    return (
      <Page
        title={intl.formatMessage({
          defaultMessage: "Player details",
        })}
      >
        <ErrorsFormatter queries={[query]} />
      </Page>
    );
  }

  const player = query.data;

  return (
    <Page
      title={
        <PageHeader
          title={intl.formatMessage({
            defaultMessage: "Player details",
          })}
          onBack={() =>
            navigate({
              pathname: "/players",
              search: window.location.search,
            })
          }
        />
      }
    >
      <CurrentBonus.Modal />
      <Card size="small">
        <Row>
          <Col xs={24} xl={18} xxl={12}>
            <Row gutter={[48, 8]} wrap>
              <Col xs={24} md={12}>
                <Row justify="space-between">
                  <Col>{intl.formatMessage({ defaultMessage: "ID" })}:</Col>
                  <Col>{player.id}</Col>
                </Row>
              </Col>
              <Col xs={24} md={12}>
                <Row justify="space-between">
                  <Col>{intl.formatMessage({ defaultMessage: "Login" })}:</Col>
                  <Col>{player.login}</Col>
                </Row>
              </Col>
              {typeof player.balance === "number" && (
                <Col xs={24} md={12}>
                  <Row justify="space-between">
                    <Col>
                      {intl.formatMessage({ defaultMessage: "Balance" })}:
                    </Col>
                    <Col>
                      <MoneyFormatter cents={player.balance} />
                    </Col>
                  </Row>
                </Col>
              )}
              <Col xs={24} md={12}>
                <Row justify="space-between">
                  <Col>{intl.formatMessage({ defaultMessage: "Shop" })}:</Col>
                  <Col>
                    <Space>
                      {permissionsStore.has("ViewHalls") && (
                        <AncestorsTreeButton
                          entity={{ hallId: player.hallId }}
                        />
                      )}
                      {player.hallName}
                    </Space>
                  </Col>
                </Row>
              </Col>
            </Row>
            {player.sweepstake !== null && (
              <>
                <Divider />
                <Typography.Title
                  level={4}
                  style={{ margin: 0, marginBottom: 8 }}
                >
                  {intl.formatMessage({ defaultMessage: "Sweepstake" })}
                </Typography.Title>
                <Row gutter={[48, 8]} wrap>
                  <Col xs={24} md={12}>
                    <Row justify="space-between">
                      <Col>
                        {intl.formatMessage({ defaultMessage: "Entries" })}:
                      </Col>
                      <Col>
                        <MoneyFormatter cents={player.sweepstake.entries} />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} md={12}>
                    <Row justify="space-between">
                      <Col>
                        {intl.formatMessage({ defaultMessage: "Total Win" })}:
                      </Col>
                      <Col>
                        <MoneyFormatter cents={player.sweepstake.win} />
                      </Col>
                    </Row>
                  </Col>
                  {typeof player.sweepstake.credit === "number" && (
                    <Col xs={24} md={12}>
                      <Row justify="space-between">
                        <Col>
                          {intl.formatMessage({ defaultMessage: "Credit" })}:
                        </Col>
                        <Col>
                          <MoneyFormatter cents={player.sweepstake.credit} />
                        </Col>
                      </Row>
                    </Col>
                  )}
                  {typeof player.sweepstake.bonus === "number" && (
                    <Col xs={24} md={12}>
                      <Row justify="space-between">
                        <Col>
                          {intl.formatMessage({ defaultMessage: "Bonus" })}:
                        </Col>
                        <Col>
                          <MoneyFormatter cents={player.sweepstake.bonus} />
                        </Col>
                      </Row>
                    </Col>
                  )}
                  {typeof player.sweepstake.redeem === "number" && (
                    <Col xs={24} md={12}>
                      <Row justify="space-between">
                        <Col>
                          {intl.formatMessage({ defaultMessage: "Redeem" })}:
                        </Col>
                        <Col>
                          <MoneyFormatter cents={player.sweepstake.redeem} />
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              </>
            )}
            <Divider />
            <Typography.Title level={4} style={{ margin: 0, marginBottom: 8 }}>
              {intl.formatMessage({ defaultMessage: "Financial" })}
            </Typography.Title>
            <Row gutter={[48, 8]} wrap>
              <Col xs={24} md={12}>
                <Row justify="space-between">
                  <Col>
                    {intl.formatMessage({ defaultMessage: "Last deposit at" })}:
                  </Col>
                  <Col>
                    {player.lastDepositDate !== null
                      ? new Date(player.lastDepositDate).toLocaleString()
                      : "—"}
                  </Col>
                </Row>
              </Col>
              <Col xs={24} md={12}>
                <Row justify="space-between">
                  <Col>
                    {intl.formatMessage({ defaultMessage: "Deposit amount" })}:
                  </Col>
                  <Col>
                    {player.lastDepositAmount !== 0 ? (
                      <MoneyFormatter cents={player.lastDepositAmount} />
                    ) : (
                      "—"
                    )}
                  </Col>
                </Row>
              </Col>
              <Col xs={24} md={12}>
                <Row justify="space-between">
                  <Col>
                    {intl.formatMessage({ defaultMessage: "Last withdraw at" })}
                    :
                  </Col>
                  <Col>
                    {player.lastWithdrawDate !== null
                      ? new Date(player.lastWithdrawDate).toLocaleString()
                      : "—"}
                  </Col>
                </Row>
              </Col>
              <Col xs={24} md={12}>
                <Row justify="space-between">
                  <Col>
                    {intl.formatMessage({ defaultMessage: "Withdraw amount" })}:
                  </Col>
                  <Col>
                    {player.lastWithdrawAmount !== 0 ? (
                      <MoneyFormatter cents={player.lastWithdrawAmount} />
                    ) : (
                      "—"
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            {player.bonus && (
              <>
                <Divider />
                <Typography.Title
                  level={4}
                  style={{ margin: 0, marginBottom: 8 }}
                >
                  {intl.formatMessage({ defaultMessage: "Current bonus" })}
                </Typography.Title>
                <ErrorsFormatter
                  queries={[cancelBonusQuery, resetWagerQuery]}
                />
                <Row gutter={[24, 8]} wrap>
                  <Col xs={24} md={12}>
                    <Row justify="space-between">
                      <Col>
                        {intl.formatMessage({ defaultMessage: "Wager" })}:
                      </Col>
                      <Col>
                        <MoneyFormatter cents={player.bonus.amount} />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} md={12}>
                    <Row justify="space-between">
                      <Col>
                        {intl.formatMessage({
                          defaultMessage: "Issued at",
                        })}
                        :
                      </Col>
                      <Col>
                        {player.bonus.lastIssuedAt !== null
                          ? new Date(player.bonus.lastIssuedAt).toLocaleString()
                          : "—"}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} md={12}>
                    <Row justify="space-between">
                      <Col>
                        {intl.formatMessage({
                          defaultMessage: "Remaining wager",
                        })}
                        :
                      </Col>
                      <Col>
                        <MoneyFormatter cents={player.bonus.remainingWager} />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} md={12}>
                    <Row justify="space-between">
                      <Col>
                        {intl.formatMessage({ defaultMessage: "Count" })}:
                      </Col>
                      <Col>
                        {player.bonus.count}{" "}
                        <Typography.Link
                          onClick={async () => {
                            eventBusService.publish(
                              new CheckPlayerBonuses({
                                playerId: player.id,
                              }),
                            );
                          }}
                        >
                          {intl.formatMessage({ defaultMessage: "Details" })}
                        </Typography.Link>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24}>
                    <Row justify="end">
                      <Col>
                        <Space>
                          <AreYouSure onYes={cancelBonusQuery.submit}>
                            <Button
                              size="small"
                              loading={cancelBonusQuery.isPending}
                            >
                              {intl.formatMessage({
                                defaultMessage: "Cancel bonus",
                              })}
                            </Button>
                          </AreYouSure>
                          <AreYouSure onYes={resetWagerQuery.submit}>
                            <Button
                              size="small"
                              loading={resetWagerQuery.isPending}
                            >
                              {intl.formatMessage({
                                defaultMessage: "Reset wager",
                              })}
                            </Button>
                          </AreYouSure>
                        </Space>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
            <Divider />
            <Typography.Title level={4} style={{ margin: 0, marginBottom: 8 }}>
              {intl.formatMessage({ defaultMessage: "Other" })}
            </Typography.Title>
            <Row gutter={[48, 8]} wrap>
              <Col xs={24} md={12}>
                <Row justify="space-between">
                  <Col>
                    {intl.formatMessage({ defaultMessage: "Registered at" })}:
                  </Col>
                  <Col>{new Date(player.createdAt).toLocaleString()}</Col>
                </Row>
              </Col>
              <Col xs={24} md={12}>
                <Row justify="space-between">
                  <Col>
                    {intl.formatMessage({ defaultMessage: "Registered by" })}:
                  </Col>
                  <Col>
                    {!player.createdByUserId ? (
                      "—"
                    ) : (
                      <>
                        {player.createdByUserLogin} ({player.createdByUserId})
                      </>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col xs={24} md={12}>
                <Row justify="space-between">
                  <Col>
                    {intl.formatMessage({ defaultMessage: "Last login at" })}:
                  </Col>
                  <Col>
                    {player.lastLoginAt
                      ? new Date(player.lastLoginAt).toLocaleString()
                      : "—"}
                  </Col>
                </Row>
              </Col>
              <Col xs={24} md={12}>
                <Row justify="space-between">
                  <Col>
                    {intl.formatMessage({ defaultMessage: "Last spin at" })}:
                  </Col>
                  <Col>
                    {player.lastSpinAt
                      ? new Date(player.lastSpinAt).toLocaleString()
                      : "—"}
                  </Col>
                </Row>
              </Col>
              <Col xs={24} md={12}>
                <Row justify="space-between">
                  <Col>
                    {intl.formatMessage({ defaultMessage: "Last game" })}:
                  </Col>
                  <Col>{player.lastGameName ?? "—"}</Col>
                </Row>
              </Col>
              <Col xs={24} md={12}>
                <Row justify="space-between">
                  <Col>
                    {intl.formatMessage({ defaultMessage: "Last login IP" })}:
                  </Col>
                  <Col>{player.lastLoginIp ?? "—"}</Col>
                </Row>
              </Col>
              <Col xs={24} md={12}>
                <Row justify="space-between">
                  <Col>
                    {intl.formatMessage({
                      defaultMessage: "Games in favorites",
                    })}
                    :
                  </Col>
                  <Col>{player.favouriteGamesCount}</Col>
                </Row>
              </Col>
            </Row>
            <Divider />
            <Typography.Title level={4} style={{ margin: 0, marginBottom: 8 }}>
              {intl.formatMessage({ defaultMessage: "KYC" })}
            </Typography.Title>
            <Row gutter={[48, 8]} wrap>
              <Col xs={24} md={12}>
                <Row justify="space-between">
                  <Col>
                    {intl.formatMessage({ defaultMessage: "KYC Confirmed" })}:
                  </Col>
                  <Col>
                    {player.flags.kycApproved
                      ? intl.formatMessage({ defaultMessage: "Yes" })
                      : intl.formatMessage({ defaultMessage: "No" })}
                  </Col>
                </Row>
              </Col>
              <Col xs={24} md={12}>
                <Row justify="space-between">
                  <Col>
                    {intl.formatMessage({ defaultMessage: "Updated at" })}:
                  </Col>
                  <Col>
                    {player.kycUpdateAt !== null
                      ? new Date(player.kycUpdateAt).toLocaleString()
                      : "—"}
                  </Col>
                </Row>
              </Col>
              <Col xs={24} md={12}>
                <Row justify="space-between">
                  <Col>{intl.formatMessage({ defaultMessage: "Reason" })}:</Col>
                  <Col>{player.kycUpdateReason ?? "—"}</Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </Page>
  );
});

import { Space, Form, Row, Button, Col } from "antd";
import { reaction } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import ErrorsFormatter from "../_common_/errors-formatter";
import {
  FormInput,
  FormItem,
  FormPassword,
  FormSwitch,
} from "../_common_/form-helpers";
import { FormSpinner } from "../_common_/form-spinner";

import { PlayerUpdateFormState } from "./player-update-form.state";

interface Props {
  state: PlayerUpdateFormState;
}

export const PlayerUpdateFormView = observer(({ state }: Props) => {
  const { intl, form, playerQuery, submitQuery } = state;

  useEffect(() => {
    reaction(
      () => submitQuery.isFulfilled,
      () => form.resetField("password"),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (playerQuery.isIdle || playerQuery.isPending) {
    return <FormSpinner />;
  }

  if (playerQuery.isRejected) {
    return <ErrorsFormatter queries={[playerQuery]} />;
  }

  return (
    <Space direction="vertical">
      <ErrorsFormatter queries={[submitQuery]} />
      <Form
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={form.handleSubmit(submitQuery.submit)}
      >
        <FormItem
          form={form}
          path="password"
          label={intl.formatMessage({ defaultMessage: "New password" })}
        >
          <FormPassword
            form={form}
            path="password"
            placeholder={intl.formatMessage({
              defaultMessage: "Enter password",
            })}
          />
        </FormItem>
        <FormItem
          form={form}
          path="name"
          label={intl.formatMessage({ defaultMessage: "Name" })}
        >
          <FormInput
            form={form}
            path="name"
            placeholder={intl.formatMessage({ defaultMessage: "Enter name" })}
          />
        </FormItem>
        <FormItem
          form={form}
          path="email"
          label={intl.formatMessage({ defaultMessage: "E-mail" })}
        >
          <FormInput
            form={form}
            path="email"
            placeholder={intl.formatMessage({ defaultMessage: "Enter email" })}
          />
        </FormItem>
        <FormItem
          form={form}
          path="phone"
          label={intl.formatMessage({ defaultMessage: "Phone number" })}
        >
          <FormInput
            form={form}
            path="phone"
            placeholder={intl.formatMessage({
              defaultMessage: "Enter phone number",
            })}
          />
        </FormItem>
        <FormItem
          form={form}
          path="isBlocked"
          label={intl.formatMessage({ defaultMessage: "Blocked" })}
        >
          <FormSwitch
            form={form}
            path="isBlocked"
            isConfirmationNeeded={true}
          />
        </FormItem>
        <FormItem
          form={form}
          path="kycApproved"
          label={intl.formatMessage({ defaultMessage: "KYC confirmed" })}
        >
          <FormSwitch
            form={form}
            path="kycApproved"
            isConfirmationNeeded={true}
          />
        </FormItem>
        <Row justify="end" gutter={12}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={submitQuery.isPending}
            >
              {intl.formatMessage({ defaultMessage: "Save" })}
            </Button>
          </Col>
        </Row>
      </Form>
    </Space>
  );
});

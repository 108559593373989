import yup from "@/yup";

export function useSchema() {
  return yup.object({
    chooseBonusId: yup.number().integer().nullable().defined(),
    autoIssueBonusKioskDeposit: yup.boolean().defined(),
    autoIssueBonusPspDeposit: yup.boolean().defined(),
    autoIssueBonusStrategy: yup
      .mixed<"chooseBonus" | "autoHandleThreshold" | null>()
      .oneOf(["chooseBonus", "autoHandleThreshold", null])
      .nullable()
      .defined(),
    autoIssueBonusPspStrategy: yup
      .mixed<"chooseBonus" | "autoHandleThreshold" | null>()
      .oneOf(["chooseBonus", "autoHandleThreshold", null])
      .nullable()
      .defined(),
    chooseBonusPspId: yup.number().integer().nullable().defined(),
    // TODO use in two bonus strategy
    // bonuses: yup
    //   .array(yup.object({ bonusId: yup.number().integer().required() }))
    //   .nullable()
    //   .defined(),
  });
}

export type IAutoIssuesSchema = yup.InferType<ReturnType<typeof useSchema>>;

import { yupResolver } from "@hookform/resolvers/yup";
import { notification } from "antd";
import { pick } from "lodash-es";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { AgentAPI } from "@/api";
import { AgentUpdated } from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";

import { Props } from "./GeneralForm";
import { ISchema, useSchema } from "./schema";

export function useGeneralFormState({ agentId, setAgentName }: Props) {
  const intl = useIntl();

  const { agentQuery, parentAgentQuery } = useMemo(() => {
    const agentQuery = new Query(AgentAPI.getById);
    const parentAgentQuery = new Query(AgentAPI.getById);

    agentQuery.submit({ id: agentId }).then(() => {
      const agent = agentQuery.data!;
      setAgentName(agent.name);
      if (agent.parentAgentId) {
        parentAgentQuery.submit({ id: agent.parentAgentId });
      }
    });

    return { agentQuery, parentAgentQuery };
  }, [agentId, setAgentName]);

  const schema = useSchema();
  const form = useForm<ISchema>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const agent = agentQuery.data;

    if (!agent) {
      return;
    }

    form.reset(
      pick(agent, [
        "isConvActive",
        "isBalanceUnlimited",
        "isBlocked",
        "canHaveChildren",
        "configuredByParent",
        "kycType",
        "gameDisplayType",
      ]),
    );
  }, [agentQuery.data, form]);

  const { eventBusService } = useGlobalStore();

  const submitQuery = useMemo(() => {
    return new Query(async (values: ISchema) => {
      await AgentAPI.update({ id: agentId, ...values });

      eventBusService.publish(new AgentUpdated({ agentId }));

      notification.success({
        message: "",
        description: intl.formatMessage({
          defaultMessage: "The agent has been successfully updated.",
        }),
      });
    });
  }, [agentId, eventBusService, intl]);

  return { agentQuery, parentAgentQuery, submitQuery, form };
}

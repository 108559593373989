import yup from "@/yup";

export function useSchema() {
  return yup.object({
    isConvActive: yup.boolean(),
    password: yup
      .string()
      .min(6)
      .transform((value) => (!value ? undefined : value)),
    isBalanceUnlimited: yup.boolean(),
    isBlocked: yup.boolean(),
    canHaveChildren: yup.boolean(),
    configuredByParent: yup.boolean(),
    gameDisplayType: yup
      .mixed()
      .oneOf(["always", "kyc_confirmed", "kyc_confirmed_or_deposit", null]),
    kycType: yup.mixed().oneOf(["manual", "provider", null]),
  });
}

export type ISchema = yup.InferType<ReturnType<typeof useSchema>>;

import { Button, Col, Form, Row, Space } from "antd";
import { observer } from "mobx-react-lite";

import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormItem, FormSwitch } from "@/ui/_common_/form-helpers";
import { FormSpinner } from "@/ui/_common_/form-spinner";

import { PosSettingsState } from "./pos-settings.state";

interface Props {
  state: PosSettingsState;
}

export const PosSettingsView = observer<Props>(
  ({ state: { intl, settingsQuery, submitQuery, form } }) => {
    if (settingsQuery.isRejected) {
      return <ErrorsFormatter queries={[settingsQuery]} />;
    }

    if (!settingsQuery.data) {
      return <FormSpinner />;
    }

    const { updatedBy, updatedAt } = settingsQuery.data;

    return (
      <Space direction="vertical">
        <ErrorsFormatter queries={[submitQuery]} />
        <Form
          labelCol={{ span: 8 }}
          labelAlign="left"
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          onFinish={form.handleSubmit(submitQuery.submit)}
        >
          <Form.Item
            label={intl.formatMessage({ defaultMessage: "Main page" })}
          />
          <div style={{ marginLeft: "12px" }}>
            <FormItem
              form={form}
              path="posSettings.shopBalance"
              label={intl.formatMessage({
                defaultMessage: "Shop balance",
              })}
              isColonShown={false}
            >
              <FormSwitch form={form} path="posSettings.shopBalance" />
            </FormItem>
            <FormItem
              form={form}
              path="posSettings.deposit"
              label={intl.formatMessage({
                defaultMessage: "Deposit",
              })}
              isColonShown={false}
            >
              <FormSwitch form={form} path="posSettings.deposit" />
            </FormItem>
            <FormItem
              form={form}
              path="posSettings.shopBalance"
              label={intl.formatMessage({
                defaultMessage: "Voucher info",
              })}
              isColonShown={false}
            >
              <FormSwitch form={form} path="posSettings.voucherInfo" />
            </FormItem>
            <FormItem
              form={form}
              path="posSettings.memberCard"
              label={intl.formatMessage({
                defaultMessage: "Member card",
              })}
              isColonShown={false}
            >
              <FormSwitch form={form} path="posSettings.memberCard" />
            </FormItem>
            <FormItem
              form={form}
              path="posSettings.kiosks"
              label={intl.formatMessage({
                defaultMessage: "Kiosks",
              })}
              isColonShown={false}
            >
              <FormSwitch form={form} path="posSettings.kiosks" />
            </FormItem>
          </div>
          <Form.Item
            label={intl.formatMessage({
              defaultMessage: "Updated",
            })}
          >
            {updatedAt ? new Date(updatedAt * 1000).toLocaleString() : "—"} /{" "}
            {updatedBy ?? "—"}
          </Form.Item>
          <Row justify="end" gutter={12}>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                loading={submitQuery.isPending}
              >
                {intl.formatMessage({ defaultMessage: "Save" })}
              </Button>
            </Col>
          </Row>
        </Form>
      </Space>
    );
  },
);

import yup from "@/yup";

export function useSchema() {
  return yup.object({
    bonusWager: yup.number().min(1).integer().required(),
    minWithdraw: yup.number().min(0).integer().required(),
    isAutoLowBonusFinish: yup.boolean().defined(),
  });
}

export type ISchema = yup.InferType<ReturnType<typeof useSchema>>;

import {
  AlertOutlined,
  CaretDownOutlined,
  EditOutlined,
  MinusOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Empty,
  Modal,
  Row,
  Space,
  Spin,
  Table,
  TablePaginationConfig,
  Tag,
  Typography,
} from "antd";
import { Popover } from "antd/es";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import classNames from "classnames";
import { Observer, observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";

import { TKioskDevices } from "@/api";
import {
  CloseEditKioskModal,
  DepositPlayerBalance,
  OpenEditKioskModal,
  WithdrawPlayerBalance,
} from "@/events";
import { KiosksStore, KioskStore, useGlobalStore } from "@/stores";
import { AncestorsTreeButton } from "@/ui/_common_/ancestors-tree";
import AreYouSure from "@/ui/_common_/are-you-sure";
import MoneyFormatter from "@/ui/_common_/money-formatter";
import Pagination from "@/ui/_common_/pagination";
import { DeleteAction } from "@/ui/delete-action";

import { Status } from "./status";

type TProps = {
  kiosksStore: KiosksStore;
};

function KiosksTable({ kiosksStore: state }: TProps) {
  const { eventBusService, permissionsStore } = useGlobalStore();

  useEffect(() => {
    const listener = () => state.filter();

    eventBusService.subscribe(CloseEditKioskModal, listener);

    return () => {
      eventBusService.unsubscribe(CloseEditKioskModal, listener);
    };
  }, [eventBusService, state]);

  const handleTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<KioskStore> | SorterResult<KioskStore>[],
    ) => {
      if (
        !Array.isArray(sorter) &&
        typeof sorter.columnKey === "string" &&
        typeof sorter.order === "string" &&
        (sorter.columnKey === "id" || sorter.columnKey === "name")
      ) {
        state.filterQuery.setOrder([
          sorter.columnKey,
          sorter.order === "ascend" ? "asc" : "desc",
        ]);
      }
      state.filter();
    },
    [state],
  );

  const intl = useIntl();

  return (
    <Spin spinning={state.filterQuery.isPending}>
      <Space direction="vertical">
        <Row>
          <Col xs={0} sm={24}>
            <Table
              dataSource={state.kioskStores}
              showHeader={!!state.kioskStores.length}
              size="small"
              rowKey={(kioskStore) => kioskStore.kiosk.id}
              bordered
              pagination={false}
              components={{ body: { row: KiosksTableRow } }}
              onChange={handleTableChange}
            >
              <Table.Column
                key="id"
                title={intl.formatMessage({ defaultMessage: "ID" })}
                dataIndex={["kiosk", "id"]}
                align="right"
                sorter={true}
                defaultSortOrder={
                  state.filterQuery.order[1] === "asc" ? "ascend" : "descend"
                }
                sortDirections={["ascend", "descend", "ascend"]}
              />
              <Table.Column
                key="name"
                title={intl.formatMessage({ defaultMessage: "Name" })}
                dataIndex={["kiosk", "name"]}
                width="100%"
                sorter={true}
                sortDirections={["ascend", "descend", "ascend"]}
                render={(name: string, kioskStore: KioskStore) => (
                  <Observer>
                    {() => (
                      <>
                        {name}{" "}
                        {!!kioskStore.kiosk.deletedAt && (
                          <Tag>
                            {intl.formatMessage({ defaultMessage: "DELETED" })}
                          </Tag>
                        )}
                        {!!kioskStore.kiosk.isPanicEnabled && (
                          <AlertOutlined style={{ color: "red" }} />
                        )}
                      </>
                    )}
                  </Observer>
                )}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Note" })}
                render={(_, kioskStore: KioskStore) => {
                  const { kiosk } = kioskStore;
                  return (
                    <div
                      style={{
                        width: "320px",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {kiosk.note}
                    </div>
                  );
                }}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Status" })}
                render={(_, kioskStore: KioskStore) => {
                  const { kiosk } = kioskStore;
                  return <Status kiosk={kiosk} />;
                }}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Balance" })}
                dataIndex={["kiosk", "playerBalance"]}
                align="right"
                render={(_, kioskStore: KioskStore) => (
                  <Observer>
                    {() => (
                      <Row wrap={false} gutter={8} justify="end">
                        <Col>
                          <Button
                            size="small"
                            icon={<MinusOutlined />}
                            title={intl.formatMessage({
                              defaultMessage: "Withdraw",
                            })}
                            onClick={() => {
                              const playerId = kioskStore.kiosk.playerId;
                              const kioskId = kioskStore.kiosk.id;
                              if (!playerId) {
                                return;
                              }
                              eventBusService.publish(
                                new WithdrawPlayerBalance({
                                  playerId,
                                  kioskId,
                                }),
                              );
                            }}
                            disabled={
                              kioskStore.kiosk.isOffline ||
                              kioskStore.kiosk.playerBalance === null
                            }
                          />
                        </Col>
                        <Col flex="auto">
                          {kioskStore.kiosk.isOffline ||
                          kioskStore.kiosk.playerBalance === null ? (
                            "—"
                          ) : (
                            <MoneyFormatter
                              cents={kioskStore.kiosk.playerBalance}
                            />
                          )}
                        </Col>
                        <Col>
                          <Button
                            size="small"
                            icon={<PlusOutlined />}
                            title={intl.formatMessage({
                              defaultMessage: "Deposit",
                            })}
                            onClick={() => {
                              const playerId = kioskStore.kiosk.playerId;
                              const kioskId = kioskStore.kiosk.id;
                              if (!playerId) {
                                return;
                              }
                              eventBusService.publish(
                                new DepositPlayerBalance({ playerId, kioskId }),
                              );
                            }}
                            disabled={
                              kioskStore.kiosk.isOffline ||
                              kioskStore.kiosk.playerBalance === null
                            }
                          />
                        </Col>
                      </Row>
                    )}
                  </Observer>
                )}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Currency" })}
                dataIndex={["kiosk", "playerCurrency"]}
                render={(playerCurrency, kioskStore: KioskStore) =>
                  kioskStore.kiosk.isOffline || playerCurrency === null
                    ? "—"
                    : playerCurrency
                }
                align="right"
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Payout" })}
                dataIndex={["kiosk", "isPayoutAllowed"]}
                render={(isPayoutAllowed: boolean | null) => (
                  <Row justify="center">
                    <Checkbox disabled checked={!!isPayoutAllowed} />
                  </Row>
                )}
                align="right"
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Payout amount" })}
                dataIndex={["kiosk", "devices"]}
                render={(devices: TKioskDevices) => {
                  return (
                    <Observer>
                      {() => {
                        if (!devices?.bills?.storage) {
                          return <>—</>;
                        }

                        return (
                          <Popover
                            trigger="click"
                            placement="bottomLeft"
                            content={
                              <div>
                                <strong>
                                  {intl.formatMessage({
                                    defaultMessage:
                                      "State of the payout module",
                                  })}
                                </strong>
                                <div>
                                  {Object.entries(
                                    devices.bills.storage.total,
                                  ).map(([currency, total]) => (
                                    <div key={currency}>
                                      <strong>
                                        {intl.formatMessage({
                                          defaultMessage: "Total",
                                        })}
                                        :
                                      </strong>{" "}
                                      {total / 100} {currency},{" "}
                                      <strong>
                                        {intl.formatMessage({
                                          defaultMessage: "Max",
                                        })}
                                        :
                                      </strong>{" "}
                                      {devices.bills.storage!.max[currency] /
                                        100}{" "}
                                      {currency}
                                    </div>
                                  ))}
                                </div>
                                <Table
                                  size="small"
                                  bordered
                                  pagination={false}
                                  dataSource={devices.bills.storage.data}
                                  columns={[
                                    {
                                      title: intl.formatMessage({
                                        defaultMessage: "Denomination",
                                      }),
                                      align: "right",
                                      render: ([denomination, , currency]) => (
                                        <>
                                          {denomination / 100} {currency}
                                        </>
                                      ),
                                    },
                                    {
                                      title: intl.formatMessage({
                                        defaultMessage: "Max",
                                      }),
                                      align: "right",
                                      render: ([, , , max]) => <>{max}</>,
                                    },
                                    {
                                      title: intl.formatMessage({
                                        defaultMessage: "Count",
                                      }),
                                      align: "right",
                                      render: ([, count]) => <>{count}</>,
                                    },
                                    {
                                      title: intl.formatMessage({
                                        defaultMessage: "Amount",
                                      }),
                                      align: "right",
                                      render: ([
                                        denomination,
                                        count,
                                        currency,
                                      ]) => (
                                        <>
                                          {(denomination * count) / 100}{" "}
                                          {currency}
                                        </>
                                      ),
                                    },
                                  ]}
                                />
                                <div>
                                  <strong>
                                    {intl.formatMessage({
                                      defaultMessage: "Updated",
                                    })}
                                    :
                                  </strong>{" "}
                                  {new Date(
                                    devices.bills.storage.ts * 1000,
                                  ).toLocaleString()}
                                  <br />
                                  <strong>
                                    {intl.formatMessage({
                                      defaultMessage: "TX",
                                    })}
                                    :
                                  </strong>{" "}
                                  {devices.bills.storage.tx}
                                </div>
                              </div>
                            }
                          >
                            <div
                              style={{ cursor: "pointer", textAlign: "right" }}
                            >
                              {Object.entries(devices.bills.storage.total)
                                .map(
                                  ([currency, total]) =>
                                    `${currency}: ${total / 100}`,
                                )
                                .join(", ")}
                            </div>
                          </Popover>
                        );
                      }}
                    </Observer>
                  );
                }}
              />
              <Table.Column
                title={intl.formatMessage({ defaultMessage: "Actions" })}
                render={(_, kioskStore: KioskStore) => (
                  <Observer>
                    {() => (
                      <Row wrap={false} gutter={8}>
                        <Col>
                          <Button
                            size="small"
                            icon={<ReloadOutlined />}
                            title={intl.formatMessage({
                              defaultMessage: "Reload",
                            })}
                            onClick={kioskStore.fetch}
                          />
                        </Col>
                        {permissionsStore.has("UpdateKiosk") && (
                          <Col>
                            <Button
                              size="small"
                              icon={<EditOutlined />}
                              title={intl.formatMessage({
                                defaultMessage: "Edit",
                              })}
                              onClick={() => {
                                eventBusService.publish(
                                  new OpenEditKioskModal({
                                    clientId:
                                      kioskStore.kiosksStore
                                        .generalSelectorsStore
                                        .clientSelectorStore.selectedId!,
                                    hallId: kioskStore.kiosk.hallId,
                                    kioskId: kioskStore.kiosk.id,
                                  }),
                                );
                              }}
                            />
                          </Col>
                        )}
                        <Col>
                          {kioskStore.kiosk.isPanicEnabled ? (
                            <Button
                              size="small"
                              icon={<AlertOutlined />}
                              title={intl.formatMessage({
                                defaultMessage: "Disable panic",
                              })}
                              loading={kioskStore.togglePanicQuery.isPending}
                              onClick={() =>
                                kioskStore.togglePanicQuery.submit()
                              }
                            />
                          ) : (
                            <AreYouSure
                              onYes={() => kioskStore.togglePanicQuery.submit()}
                            >
                              <Button
                                size="small"
                                icon={<AlertOutlined />}
                                title={intl.formatMessage({
                                  defaultMessage: "Enable panic",
                                })}
                                loading={kioskStore.togglePanicQuery.isPending}
                              />
                            </AreYouSure>
                          )}
                        </Col>
                        <Col>
                          <DeleteAction
                            size="small"
                            entity={{ kioskId: kioskStore.kiosk.id }}
                            isDeleted={!!kioskStore.kiosk.deletedAt}
                            onSuccess={state.filter}
                          />
                        </Col>
                      </Row>
                    )}
                  </Observer>
                )}
              />
            </Table>
          </Col>
          <Col xs={24} sm={0}>
            {!state.kioskStores.length && <Empty />}
            <Space direction="vertical">
              {state.kioskStores.map((kioskStore) => (
                <Card
                  key={kioskStore.kiosk.id}
                  size="small"
                  className={classNames({
                    "ant-table-row-color-gray": !!kioskStore.kiosk.deletedAt,
                  })}
                >
                  <Row gutter={[8, 8]}>
                    {!!kioskStore.kiosk.deletedAt && (
                      <Tag>
                        {intl.formatMessage({ defaultMessage: "DELETED" })}
                      </Tag>
                    )}
                  </Row>
                  <Row gutter={[8, 8]}>
                    <Col
                      flex={1}
                      style={{
                        fontWeight: "bold",
                        color: kioskStore.kiosk.isBlocked ? "red" : "",
                      }}
                    >
                      {kioskStore.kiosk.name}{" "}
                      {!!kioskStore.kiosk.isPanicEnabled && (
                        <AlertOutlined style={{ color: "red" }} />
                      )}
                    </Col>
                    <Col style={{ textAlign: "right" }}>
                      {kioskStore.kiosk.id}
                    </Col>
                  </Row>
                  <Row gutter={[8, 8]}>
                    <Col style={{ color: "gray" }}>
                      {intl.formatMessage({ defaultMessage: "Status" })}:{" "}
                      <Status kiosk={kioskStore.kiosk} />
                    </Col>
                  </Row>
                  {kioskStore.kiosk.isPayoutAllowed && (
                    <Row gutter={[8, 8]}>
                      <Col style={{ color: "gray" }}>
                        {intl.formatMessage({
                          defaultMessage: "Payout is allowed",
                        })}
                      </Col>
                    </Row>
                  )}
                  {kioskStore.kiosk.devices && (
                    <Row gutter={[8, 8]}>
                      <Col style={{ color: "gray" }}>
                        {intl.formatMessage({
                          defaultMessage: "Payout amount",
                        })}
                        :{" "}
                        <Observer>
                          {() => {
                            const devices = kioskStore.kiosk.devices;

                            if (!devices?.bills?.storage) {
                              return <>—</>;
                            }

                            return (
                              <Typography.Link
                                onClick={() => {
                                  const storage = devices.bills.storage!;
                                  Modal.info({
                                    bodyStyle: { padding: 8 },
                                    icon: null,
                                    title: intl.formatMessage({
                                      defaultMessage:
                                        "State of the payout module",
                                    }),
                                    content: (
                                      <>
                                        <div>
                                          {Object.entries(storage.total).map(
                                            ([currency, total]) => (
                                              <div key={currency}>
                                                <strong>
                                                  {intl.formatMessage({
                                                    defaultMessage: "Total",
                                                  })}
                                                  :
                                                </strong>{" "}
                                                {total / 100} {currency},{" "}
                                                <strong>
                                                  {intl.formatMessage({
                                                    defaultMessage: "Max",
                                                  })}
                                                  :
                                                </strong>{" "}
                                                {storage.max[currency] / 100}{" "}
                                                {currency}
                                              </div>
                                            ),
                                          )}
                                        </div>
                                        <Table
                                          size="small"
                                          bordered
                                          pagination={false}
                                          dataSource={storage.data}
                                          columns={[
                                            {
                                              title: intl.formatMessage({
                                                defaultMessage: "Denomination",
                                              }),
                                              align: "right",
                                              render: ([
                                                denomination,
                                                ,
                                                currency,
                                              ]) => (
                                                <>
                                                  {denomination / 100}{" "}
                                                  {currency}
                                                </>
                                              ),
                                            },
                                            {
                                              title: intl.formatMessage({
                                                defaultMessage: "Max",
                                              }),
                                              align: "right",
                                              render: ([, , , max]) => (
                                                <>{max}</>
                                              ),
                                            },
                                            {
                                              title: intl.formatMessage({
                                                defaultMessage: "Count",
                                              }),
                                              align: "right",
                                              render: ([, count]) => (
                                                <>{count}</>
                                              ),
                                            },
                                            {
                                              title: intl.formatMessage({
                                                defaultMessage: "Amount",
                                              }),
                                              align: "right",
                                              render: ([
                                                denomination,
                                                count,
                                                currency,
                                              ]) => (
                                                <>
                                                  {(denomination * count) / 100}{" "}
                                                  {currency}
                                                </>
                                              ),
                                            },
                                          ]}
                                        />
                                        <div>
                                          <strong>
                                            {intl.formatMessage({
                                              defaultMessage: "Updated",
                                            })}
                                            :
                                          </strong>{" "}
                                          {new Date(
                                            storage.ts * 1000,
                                          ).toLocaleString()}
                                          <br />
                                          <strong>
                                            {intl.formatMessage({
                                              defaultMessage: "TX",
                                            })}
                                            :
                                          </strong>{" "}
                                          {storage.tx}
                                        </div>
                                      </>
                                    ),
                                  });
                                }}
                              >
                                {Object.entries(devices.bills.storage.total)
                                  .map(
                                    ([currency, total]) =>
                                      `${currency}: ${total / 100}`,
                                  )
                                  .join(", ")}
                              </Typography.Link>
                            );
                          }}
                        </Observer>
                      </Col>
                    </Row>
                  )}
                  <Divider type="horizontal" style={{ margin: "8px 0" }} />
                  <Row wrap={false} gutter={[8, 8]} align="middle">
                    <Col>
                      <Button
                        icon={<MinusOutlined />}
                        title={intl.formatMessage({
                          defaultMessage: "Withdraw",
                        })}
                        onClick={() => {
                          const playerId = kioskStore.kiosk.playerId;
                          const kioskId = kioskStore.kiosk.id;
                          if (!playerId) {
                            return;
                          }
                          eventBusService.publish(
                            new WithdrawPlayerBalance({ playerId, kioskId }),
                          );
                        }}
                        disabled={
                          kioskStore.kiosk.isOffline ||
                          kioskStore.kiosk.playerBalance === null
                        }
                      />
                    </Col>
                    <Col flex={1} style={{ textAlign: "right" }}>
                      {kioskStore.kiosk.playerBalance !== null ? (
                        <MoneyFormatter
                          cents={kioskStore.kiosk.playerBalance}
                        />
                      ) : (
                        "—"
                      )}{" "}
                      {kioskStore.kiosk.playerCurrency &&
                        kioskStore.kiosk.playerCurrency}
                    </Col>
                    <Col>
                      <Button
                        icon={<PlusOutlined />}
                        title={intl.formatMessage({
                          defaultMessage: "Deposit",
                        })}
                        onClick={() => {
                          const playerId = kioskStore.kiosk.playerId;
                          const kioskId = kioskStore.kiosk.id;
                          if (!playerId) {
                            return;
                          }
                          eventBusService.publish(
                            new DepositPlayerBalance({ playerId, kioskId }),
                          );
                        }}
                        disabled={
                          kioskStore.kiosk.isOffline ||
                          kioskStore.kiosk.playerBalance === null
                        }
                      />
                    </Col>
                    <Col>
                      <Divider type="vertical" />
                    </Col>
                    <Col>
                      <Button
                        icon={<ReloadOutlined />}
                        title={intl.formatMessage({ defaultMessage: "Reload" })}
                        loading={kioskStore.fetchQuery.isPending}
                        onClick={kioskStore.fetch}
                      />
                    </Col>
                    <Col>
                      <Popover
                        trigger="click"
                        zIndex={999}
                        content={
                          <Row gutter={[16, 8]}>
                            <Col>
                              <AncestorsTreeButton
                                size="middle"
                                entity={{ hallId: kioskStore.kiosk.hallId }}
                              />
                            </Col>
                            {permissionsStore.has("UpdateKiosk") && (
                              <Col>
                                <Button
                                  icon={<EditOutlined />}
                                  title={intl.formatMessage({
                                    defaultMessage: "Edit",
                                  })}
                                  onClick={() => {
                                    eventBusService.publish(
                                      new OpenEditKioskModal({
                                        clientId:
                                          kioskStore.kiosksStore
                                            .generalSelectorsStore
                                            .clientSelectorStore.selectedId!,
                                        hallId: kioskStore.kiosk.hallId,
                                        kioskId: kioskStore.kiosk.id,
                                      }),
                                    );
                                  }}
                                />
                              </Col>
                            )}
                            <Col>
                              {kioskStore.kiosk.isPanicEnabled ? (
                                <Button
                                  icon={<AlertOutlined />}
                                  title={intl.formatMessage({
                                    defaultMessage: "Disable panic",
                                  })}
                                  loading={
                                    kioskStore.togglePanicQuery.isPending
                                  }
                                  onClick={() =>
                                    kioskStore.togglePanicQuery.submit()
                                  }
                                />
                              ) : (
                                <AreYouSure
                                  onYes={() =>
                                    kioskStore.togglePanicQuery.submit()
                                  }
                                >
                                  <Button
                                    icon={<AlertOutlined />}
                                    title={intl.formatMessage({
                                      defaultMessage: "Enable panic",
                                    })}
                                    loading={
                                      kioskStore.togglePanicQuery.isPending
                                    }
                                  />
                                </AreYouSure>
                              )}
                            </Col>
                            <Col>
                              <DeleteAction
                                entity={{ kioskId: kioskStore.kiosk.id }}
                                isDeleted={!!kioskStore.kiosk.deletedAt}
                                onSuccess={state.filter}
                              />
                            </Col>
                          </Row>
                        }
                        placement="bottomRight"
                      >
                        <Button icon={<CaretDownOutlined />} />
                      </Popover>
                    </Col>
                  </Row>
                </Card>
              ))}
            </Space>
          </Col>
        </Row>
        <Pagination
          query={state.filterQuery}
          onChange={() => {
            state.filter({ preservePageNumber: true });
          }}
        />
      </Space>
    </Spin>
  );
}

type TChild = { props: { record: KioskStore } };

type TKiosksTableRowProps = {
  className: string;
  children: TChild | TChild[];
};

const KiosksTableRow = observer(function KiosksTableRow(
  props: TKiosksTableRowProps,
) {
  if (!Array.isArray(props.children)) {
    return <tr {...props} />;
  }
  const kioskStore = props.children[0].props.record;
  if (kioskStore.fetchQuery.isPending || kioskStore.updateQuery.isPending) {
    return (
      <tr className={props.className}>
        <td colSpan={99999}>
          <Spin size="small">
            <Button size="small" style={{ visibility: "hidden" }}>
              LOADING...
            </Button>
          </Spin>
        </td>
      </tr>
    );
  }
  return (
    <tr
      {...props}
      className={classNames(props.className, {
        "ant-table-row-color-red": kioskStore.kiosk.isBlocked,
        "ant-table-row-color-gray": !!kioskStore.kiosk.deletedAt,
      })}
    />
  );
});

export default observer(KiosksTable);

import { Space, Form, Row, Col, Button, Typography } from "antd";
import { observer } from "mobx-react-lite";

import { isTruthy } from "@/utilities";

import ErrorsFormatter from "../_common_/errors-formatter";
import {
  FormInput,
  FormItem,
  FormRadioGroup,
  FormSelect,
  FormSwitch,
} from "../_common_/form-helpers";
import { FormSpinner } from "../_common_/form-spinner";

import { KioskSettingsFormState } from "./kiosk-settings-form.state";

interface Props {
  state: KioskSettingsFormState;
}

export const KioskSettingsFormView = observer<Props>(({ state }) => {
  const { intl, form, kioskQuery, hallQuery, restartQuery, submitQuery } =
    state;

  if (
    kioskQuery.isIdle ||
    kioskQuery.isPending ||
    hallQuery.isIdle ||
    hallQuery.isPending
  ) {
    return <FormSpinner />;
  }

  if (kioskQuery.isRejected || hallQuery.isRejected) {
    return <ErrorsFormatter queries={[kioskQuery, hallQuery]} />;
  }

  const kiosk = kioskQuery.data;
  const hall = hallQuery.data;

  return (
    <Space direction="vertical">
      <ErrorsFormatter queries={[submitQuery]} />
      <Form
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={form.handleSubmit(submitQuery.submit)}
      >
        <Form.Item label={intl.formatMessage({ defaultMessage: "Shop" })}>
          <Typography.Text copyable>{hall.name}</Typography.Text>
        </Form.Item>
        <FormItem
          form={form}
          path="name"
          label={intl.formatMessage({ defaultMessage: "Name" })}
        >
          <FormInput
            form={form}
            path="name"
            placeholder={intl.formatMessage({ defaultMessage: "Enter name" })}
          />
        </FormItem>
        <FormItem
          form={form}
          path="type"
          label={intl.formatMessage({ defaultMessage: "Type" })}
        >
          <FormSelect
            form={form}
            path="type"
            options={[
              {
                label: intl.formatMessage({ defaultMessage: "Kiosk" }),
                value: "kiosk",
              },
              {
                label: intl.formatMessage({ defaultMessage: "Exchanger" }),
                value: "exchanger",
              },
              {
                label: intl.formatMessage({ defaultMessage: "Internet Cafe" }),
                value: "internetcafe",
              },
            ]}
          />
        </FormItem>
        <FormItem
          form={form}
          path="isAlwaysAuthorized"
          label={intl.formatMessage({ defaultMessage: "Always authorized" })}
        >
          <FormRadioGroup
            form={form}
            path="isAlwaysAuthorized"
            optionType="button"
            buttonStyle="solid"
            options={[
              {
                label: intl.formatMessage({ defaultMessage: "Yes" }),
                value: "true",
              },
              {
                label: intl.formatMessage({ defaultMessage: "No" }),
                value: "false",
              },
              kiosk.isAlwaysAuthorized === "default" && {
                label: intl.formatMessage({ defaultMessage: "Default" }),
                value: "default",
              },
            ].filter(isTruthy)}
          />
        </FormItem>
        <FormItem
          form={form}
          path="isPayoutAllowed"
          label={intl.formatMessage({ defaultMessage: "Payout allowed" })}
        >
          <FormSwitch form={form} path="isPayoutAllowed" />
        </FormItem>
        <FormItem
          form={form}
          path="isPanicEnabled"
          label={intl.formatMessage({ defaultMessage: "Panic" })}
        >
          <FormSwitch
            form={form}
            path="isPanicEnabled"
            isConfirmationNeeded={true}
          />
        </FormItem>
        <FormItem
          form={form}
          path="isBlocked"
          label={intl.formatMessage({ defaultMessage: "Blocked" })}
        >
          <FormSwitch
            form={state.form}
            path="isBlocked"
            isConfirmationNeeded={true}
          />
        </FormItem>
        <FormItem
          form={form}
          path="areReportsAllowed"
          label={intl.formatMessage({ defaultMessage: "Reports allowed" })}
        >
          <FormSwitch form={form} path="areReportsAllowed" />
        </FormItem>
        <Form.Item
          label={intl.formatMessage({ defaultMessage: "Last report req at" })}
        >
          {kiosk.lastReportReqAt
            ? new Date(kiosk.lastReportReqAt).toLocaleString()
            : "—"}
        </Form.Item>
        <FormItem
          form={form}
          path="title"
          label={intl.formatMessage({ defaultMessage: "Title" })}
        >
          <FormInput
            form={form}
            path="title"
            placeholder={intl.formatMessage({ defaultMessage: "Enter title" })}
          />
        </FormItem>
        <FormItem
          form={form}
          path="note"
          label={intl.formatMessage({ defaultMessage: "Note" })}
        >
          <FormInput
            form={form}
            path="note"
            placeholder={intl.formatMessage({ defaultMessage: "Enter note" })}
          />
        </FormItem>
        {/*
        <Form.Item
          label={intl.formatMessage({
            defaultMessage: "Cash acceptor balance",
          })}
        >
          {kiosk.cashAcceptorBalance === null ? (
            "—"
          ) : (
            <Typography.Text copyable>
              <MoneyFormatter
                cents={kiosk.cashAcceptorBalance}
                currency={hall.currency}
              />
            </Typography.Text>
          )}
        </Form.Item>
        */}
        {/*
        <Form.Item
          label={intl.formatMessage({ defaultMessage: "Cash collected at" })}
        >
          {kiosk.cashCollectedAt === null
            ? "—"
            : new Date(kiosk.cashCollectedAt).toLocaleString()}
        </Form.Item>
        */}
        <Row justify="end" gutter={12}>
          <Col>
            <Button
              onClick={restartQuery.submit}
              loading={restartQuery.isPending}
            >
              {intl.formatMessage({ defaultMessage: "Restart" })}
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={submitQuery.isPending}
            >
              {intl.formatMessage({ defaultMessage: "Save" })}
            </Button>
          </Col>
        </Row>
      </Form>
    </Space>
  );
});

import { TKiosk, TPaging } from "..";
import MethodBuilder from "../method-builder";

class KioskAPI {
  static filter = new MethodBuilder<
    ({ clientId?: number } | { agentId?: number } | { hallId?: number }) & {
      searchQuery?: string;
      orderBy?: ["id" | "name", "asc" | "desc"];
      includeDeleted?: boolean;
      paging: TPaging;
    },
    { data: TKiosk[]; total: number }
  >()
    .withName("Kiosk.filter")
    .build();

  static getById = new MethodBuilder<{ id: number }, TKiosk>()
    .withName("Kiosk.getById")
    .build();

  static create = new MethodBuilder<
    {
      hallId: number;
      name: string;
      pairingCode: string;
      type: "kiosk" | "exchanger" | "internetcafe";
      isAlwaysAuthorized: "true" | "false" | "default";
      isPayoutAllowed: boolean;
      note?: string;
    },
    TKiosk
  >()
    .withName("Kiosk.create")
    .build();

  static update = new MethodBuilder<
    {
      id: number;
      type?: "kiosk" | "exchanger" | "internetcafe";
      isAlwaysAuthorized?: "true" | "false" | "default";
      isPanicEnabled?: boolean;
      isBlocked?: boolean;
      isPayoutAllowed?: boolean;
      areReportsAllowed?: boolean;
      note?: string;
    },
    void
  >()
    .withName("Kiosk.update")
    .build();

  static restart = new MethodBuilder<{ id: number }, void>()
    .withName("Kiosk.restart")
    .build();

  static delete = new MethodBuilder<{ id: number }, void>()
    .withName("Kiosk.delete")
    .build();

  static undelete = new MethodBuilder<{ id: number }, void>()
    .withName("Kiosk.undelete")
    .build();
}

export default KioskAPI;
